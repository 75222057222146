import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS  } from '@angular/common/http';
import {MatNativeDateModule} from '@angular/material/core';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { MainformComponent } from './components/mainform/mainform.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LanguageComponent } from './components/language/language.component';
import { SharedModule } from './sharedModules/shared.module';

import { TranslateService } from '@ngx-translate/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { DragDropDirective } from './directives/drag-drop.directive';
import { PhoneDirective } from './directives/phone.directive';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper'
import {MatInputModule} from '@angular/material/input'
import {MatButtonModule} from '@angular/material/button'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Step1Component } from './components/mainform/steps/step1/step1.component';
import { Step2Component } from './components/mainform/steps/step2/step2.component';
import { Step3Component } from './components/mainform/steps/step3/step3.component';
import { Step4Component } from './components/mainform/steps/step4/step4.component';

import { DateAdapter } from '@angular/material/core'
import { MAT_DATE_FORMATS } from '@angular/material/core'
import {CUSTOM_DATE_FORMATS} from './sharedModules/date-picker/date-adapter';
//import {CustomDatePickerAdapter} from './sharedModules/date-picker/date-adapter';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeHeExtra from '@angular/common/locales/extra/he';
import localeHe from '@angular/common/locales/he';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeHe, 'he', localeHeExtra);

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    IntroductionComponent,
    MainformComponent,
    LanguageComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    DragDropDirective,
    PhoneDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FlexLayoutModule,
    HttpClientModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGoogleAnalyticsModule.forRoot('traking-code'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],

  providers: [
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }},
    //{provide: DateAdapter, useClass: CustomDatePickerAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    {provide: 'googleTagManagerId',  useValue: 'GTM-N4299S5'}
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private translate: TranslateService, private dateAdapter: DateAdapter<Date>) {
    translate.addLangs(['en', 'de', 'fr','es','it','pt','ru','he','tr' ]);
    //check if we have language saved in the local storage- if is we will use it, if not we will use english
    let lang= localStorage.getItem("currentLanguage");
    if(lang){
      translate.setDefaultLang(lang);
      translate.use(lang); 
      this.dateAdapter.setLocale(lang); //set the date format according to the selected lang 
    }
    else{
      translate.setDefaultLang('en');
      translate.use('en');
      this.dateAdapter.setLocale('en'); //set the date format according to the selected lang 
  
    }
  }

 }



// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
