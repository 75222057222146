import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  year: number = new Date().getFullYear();
  ProductInfoSheets : string;
  UserManuals : string;
  PrivacyPolicy : string;

  message:string;


  constructor() { }

  ngOnInit(): void {
    
  }

}
