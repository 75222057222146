import { Component, OnInit, Input, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegistrationApiService } from  'src/app/services/registrationApi.service';
import {HttpClient,HttpEventType,HttpErrorResponse} from "@angular/common/http/";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { RegParams } from '../../mainform.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
export class Step4Component implements OnInit {


  constructor( private registrationApiService: RegistrationApiService ,private http: HttpClient, private gtmService: GoogleTagManagerService) { }

  @Input() regForm: FormGroup;
  @Input() RegParams: RegParams;
  @Output() regResult: EventEmitter<any> = new EventEmitter<any>();

  formSubmitted: boolean = false;
  regSuccess: boolean = false;
  regFailed: boolean = false;
  ServerError:boolean= false;

  ngOnInit(): void {
    
  }


  submit() {
    console.log('submitted');
    console.log(this.regForm.value);
    this.formSubmitted = true;

    this.registrationApiService.sendReg(this.regForm, this.RegParams).subscribe(value => {
      //reg completed successfully
      if(value > -1){
        this.regSuccess = true;
        this.RegParams.RegCompleted = true;
        this.sentToGoogleAnalyticSuccess();
        this.regFailed= false;
        this.regResult.emit("regCompleted"); //send msg to the parent component
        console.log("reg was completed! reg ID: " + value);
      }
      else{ //error in reg - failed
        this.sentToGoogleAnalyticError();
        this.regSuccess = false;
        this.regFailed= true;
        this.regResult.emit("regFailed"); //send msg to the parent component
        console.log("Error- reg was not completed");
      }
    },
    error => {
      this.sentToGoogleAnalyticError();
      this.regSuccess = false;
      this.regFailed= true;
      this.ServerError = true;
      this.regResult.emit("regFailed"); //send msg to the parent component
      console.log("Error- reg was not completed");
      console.log("!!!!!!!!!!!!!!!!Server Error 500!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    }); 

    this.regForm.disable(); //make all the form disable
  }

//google analytic success
  sentToGoogleAnalyticSuccess(){
    //submit
    const gtmTag = {
      event: 'continue buttons',
      eventCategory: 'continue buttons',
      eventAction: 'submit'
    };
  this.gtmService.pushTag(gtmTag);
  //additional data
    let event: string = 'additional data';
    let promotionalInformationCheckbox: string = this.regForm.get('resellerDetails').get('approveReceive').value;
    let poolDim = "User did not choose";
    let poolShape = "User did not choose";
    if(this.regForm.get('personalDetails').get('poolDimension').value == "1"){
      poolShape = "Free Form";
    }
    else if(this.regForm.get('personalDetails').get('poolDimension').value == "0"){
      poolShape = "Rectangular";
      let l = (this.regForm.get('personalDetails').get('poolLength').value != "") ? this.regForm.get('personalDetails').get('poolLength').value : "User did not choose";
      let w = (this.regForm.get('personalDetails').get('poolWidth').value != "") ? this.regForm.get('personalDetails').get('poolWidth').value : "User did not choose";
      poolDim = l + " X " + w;
    }
    let AlarmDropTest = this.regForm.get('alarmDetails').get('AlarmPosition').value;
    let AlarmAudioTest = this.regForm.get('alarmDetails').get('AlarmTest').value;
  
  const gtmTag1 = {
    'event': event, 
    'promotional information checkbox': promotionalInformationCheckbox,
    'pool shape': poolShape,
    'pool size' : poolDim,
    'alarm drop test pass' : AlarmDropTest,
    'alarm audio test pass' : AlarmAudioTest
  };
  this.gtmService.pushTag(gtmTag1);
  }
  
//google analytic error
sentToGoogleAnalyticError(){
  const gtmTag = {
    event: 'continue buttons',
    eventCategory: 'continue buttons',
    eventAction: 'error'
  };
  this.gtmService.pushTag(gtmTag);
}


  get SerialNum() {
    return this.regForm.get('alarmDetails').get('SerialNum');
  }
  get firstName() {
    return this.regForm.get('personalDetails').get('firstName');
  }
  get lastName() {
    return this.regForm.get('personalDetails').get('lastName');
  }
  get email() {
    return this.regForm.get('personalDetails').get('email');
  }
  get purchaseDate() {
    return this.regForm.get('resellerDetails').get('purchaseDate');
  }
  get purchaseDay() {
    return this.regForm.get('resellerDetails').get('day');
  }
  get purchaseMonth() {
    return this.regForm.get('resellerDetails').get('month');
  }
  get purchaseYear() {
    return this.regForm.get('resellerDetails').get('year');
  }
  
}
