import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import { VisitorsService } from '../../services/visitors.service';

interface Lang {
  value: string;
  viewValue: string;
  flag:string;
}

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  langSelected: string ;
  flagSelected: string ="assets/flags/fr.png";
  langGlobalIcon: string ="assets/flags/global.png";

  languageList: Lang[] = [
    {value: 'fr', viewValue: 'Français', flag:'assets/flags/fr.png'},
    {value: 'en', viewValue: 'English', flag:'assets/flags/en.png'}
    // {value: 'es', viewValue: 'Español', flag:'assets/flags/es.png'},
    // {value: 'pt', viewValue: 'Portugues', flag:'assets/flags/pt.png'},
    // {value: 'ru', viewValue: 'Pусский', flag:'assets/flags/ru.png'},
    // {value: 'de', viewValue: 'Deutsche', flag:'assets/flags/de.png'},
    // {value: 'he', viewValue: 'עברית', flag:'assets/flags/he.png'},
    // {value: 'tr', viewValue: 'Türk', flag:'assets/flags/tr.png'},
    // {value: 'it', viewValue: 'Italiana', flag:'assets/flags/it.png'}
  ];

  langArray= ['fr','en'];


  constructor( public translate: TranslateService, private dateAdapter: DateAdapter<Date>,  private visitorsService:VisitorsService) {
    //check if we have language saved in the local storage- if yes, we will use it, if not we will use fr
    let lang= localStorage.getItem("currentLanguage");
    if(lang){
      this.langSelected = lang;
      if(lang=="en"){ //only if the lang is english we will take the global white flag 
        this.flagSelected = 'assets/flags/' + lang + '1.png';
      }
      else{
        this.flagSelected = 'assets/flags/' + lang + '.png';
      }
    }
    else{ //by default 'fr'
      this.changeLang('fr');
    }
//else get lang from ip
  //   else{
  //     //this.langSelected ='en';
  //     try{
  //       //get language by ip address of the user
  //       this.visitorsService.getIpAddress().subscribe(res => {
  //         let ipAddress = res['ip'];
  //         //let ipAddress = '176.158.143.152'; //fr
  //         //let ipAddress = '91.106.16.248'; //es
  //         //let ipAddress = '	80.210.104.253'; //it
  //         //let ipAddress = '2.247.253.37'; //de
  //         //let ipAddress = '161.230.110.235'; //pt
  //         //let ipAddress = '	212.116.188.129'; //he
  //         //let ipAddress = '213.254.128.117'; //tr
  //         //let ipAddress = '91.122.127.201'; //ru
  //         //let ipAddress = '145.249.21.44'; //syria (other)
  //         this.visitorsService.getGEOLocation(ipAddress).subscribe(res => {
  //           //console.log(res); //print all details of the ip country (array)
  //           let languages = res['languages'];
  //           let mainLang = languages.split(",")[0].split("-")[0]; //get the main lang (example- only fr and not fr-FR)
  //           console.log(mainLang);
  //           //only if the ip is from one of our available languages we will set it 
  //           if(this.langArray.indexOf(mainLang) > -1){
  //             this.changeLang(mainLang); //change to this language
  //           } //if from other country we will stay with the default - fr
  //           else{
  //             this.langSelected ='fr';
  //           }
  //         });
  //       });
  //     }
  //   catch (Error)   
  //   {  
  //     this.langSelected ='fr';
  //   }  
  // }

    //localStorage.setItem("currentLanguage", 'fr'); //set default language 'fr' into a local storage
  }

  ngOnInit(): void {
   
  }


  changeLang(lang: string) {
    this.translate.use(lang); //set to the selected lang
    localStorage.setItem("currentLanguage", lang); //set current language into a local storage
    this.dateAdapter.setLocale(lang); //set the date format according to the selected lang

    this.langSelected = lang;
    if(lang=="en"){ //only if the lang is english we will take the global white flag 
      this.flagSelected = 'assets/flags/' + lang + '1.png';
    }
    else{
      this.flagSelected = 'assets/flags/' + lang + '.png';
    }
    console.log(this.langSelected);
    console.log(this.flagSelected);

  }


}
