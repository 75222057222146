import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";

export interface RegParams {
  SerialImgURL: string;
  PartName: string;
  PartDes: string;
  Part: string;
  step3Valid: boolean;
  RegCompleted: boolean;
}

@Component({
  selector: 'app-mainform',
  templateUrl: './mainform.component.html',
  styleUrls: ['./mainform.component.css']
})

export class MainformComponent implements OnInit{

  registrationForm: FormGroup;
  RegParams: RegParams = {SerialImgURL:"",PartName:"", PartDes:"",  Part: "" , step3Valid: false, RegCompleted:false};
  
  //set the initial icons for the steps
  Step4State : string = "summary";
  Step3State : string = "resellerInfo";
  Step2State : string = "personalInfo";
  Step1State : string = "alarmInfo";

  PhoneNumberPattern = "^[0-9_-+]{10,20}$";

  constructor(private _formBuilder: FormBuilder) {
  }


  //function that receive the reg result from step 4
  public getRegResultFromStep4(res: any):void {
    if(res == "regCompleted"){ //if reg was complete successfully, set all the step icons to done 
      this.Step4State = "done";
      this.Step3State = "done";
      this.Step2State = "done";
      this.Step1State = "done";
    }
    else{ //reg was failed
      //this.Step4State = "failed";
    }
    console.log('massage from child component: ', res);
}


  ngOnInit(): void {
    this.registrationForm = new FormGroup({
      'alarmDetails':new FormGroup({
        'SerialNum':new FormControl(null, [Validators.required, , Validators.minLength(9) , Validators.maxLength(9)]),
        'purchaseDate': new FormControl(new Date(), Validators.required), //set default as today
        'year': new FormControl(new Date().getFullYear()), //set default as current year
        'month': new FormControl(new Date().getMonth()+1), //set default month as current month (to get current we need to add 1 to the getMonth function)
        'day': new FormControl(new Date().getDate()), //set default as current date 
        'AlarmPosition': new FormControl(null, Validators.required),
        'AlarmTest': new FormControl(null, Validators.required),
        'invoiceNumber':new FormControl(null, [Validators.required , Validators.maxLength(15)])
      }),
      'personalDetails':new FormGroup({
        'firstName': new FormControl(null, [Validators.required , Validators.maxLength(20)]),
        'lastName':new FormControl(null, [Validators.required , Validators.maxLength(30)]),
        'prePhone': new FormControl(null, [Validators.required , Validators.maxLength(3)]),
        'phone': new FormControl(null, [Validators.required , Validators.maxLength(15)]),
        'email': new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(40), Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
        'address': new FormControl(null, [Validators.maxLength(32)]),
        'country': new FormControl("France", [Validators.required]),
        'state': new FormControl(null, Validators.required),
        'city': new FormControl(null, [Validators.required , Validators.maxLength(30)]),
        'zipCode': new FormControl(null, [Validators.required , Validators.maxLength(10)]),
        'poolDimension': new FormControl(null, [Validators.maxLength(40)]),
        'poolLength': new FormControl({value:null, disabled: true}, [Validators.maxLength(3)]),
        'poolWidth': new FormControl({value:null, disabled: true}, [Validators.maxLength(3)]),
      },{ updateOn: "blur" }),
      'resellerDetails':new FormGroup({
        'resellerName': new FormControl(null, [Validators.required , Validators.maxLength(50)]),
        'resellerCity': new FormControl(null, Validators.maxLength(30)),
        'resellerZipCode': new FormControl(null, Validators.maxLength(10)),
        //'proofOfPurchase': new FormControl(null, Validators.required),
        'approveReceive': new FormControl(false),
        'confirmPrivacyPolicy': new FormControl(false, Validators.requiredTrue)
      }),
      
    })
}

}
