import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType ,HttpHeaders, HttpParams } from  '@angular/common/http/';
import { retry, catchError } from 'rxjs/operators';
import { map } from  'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { truncate } from 'fs';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RegParams } from '../components/mainform/mainform.component';

@Injectable({
  providedIn: 'root'
})
export class RegistrationApiService {

  private RegistrationApiBaseUrl = environment.RegistrationApiBaseUrl // URL to web api
  private regUrl = '/api/ngRegistrationAlarmFR';  

  constructor(private httpClient: HttpClient ) { }


  private httpOptions() {      
    let httpOptions = {
         headers: new HttpHeaders({
           //'Content-Type': 'application/json',
           'Content-Type': 'application/x-www-form-urlencoded',
           'AppKey':'85D112B5-B290-4D7B-8936-C0AC2E92232E'
         })
       }
       return httpOptions;
 }


  //check if email is valid
   //res=="Y" - valid  
    //res=="N" - not valid 
  isEmailValid(email:string) : Observable<any>{
    let body = new HttpParams();
    body = body.set('emailaddress', email);
    return this.httpClient.post(`${this.RegistrationApiBaseUrl}${this.regUrl}/IsEmailValid`, body, this.httpOptions())
    .pipe(
      catchError(this.errorHand)
    );
  } 

  //function to send the temporary code for second reg process
  // sendTempCodeForSecondRegProcess(email:string, code: string, name: string) : Observable<any>{
  //   let body = new HttpParams();
  //   body = body.set('email', email);
  //   body = body.set('code', code);
  //   body = body.set('name', name);
  //   body = body.set('lang', localStorage.getItem("currentLanguage"));
  //   return this.httpClient.post(`${this.RegistrationApiBaseUrl}${this.regUrl}/sendTempCodeForSecondRegProcess`, body, this.httpOptions())
  //   .pipe(
  //     catchError(this.errorHand)
  //   );
  // } 


  //function that receive a file and convert it to base64 
  getBase64(file : File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //upload the reg pop to api
  uploadFile( base64File:any, fileName: string, contentTypeFile : string ,serNum:string) : Observable<any>{
    let body = new HttpParams();
    body = body.set('base64File', base64File);
    body = body.set('fileName', fileName);
    body = body.set('contentTypeFile', contentTypeFile);
    body = body.set('serNum', serNum);
    // console.log(serNum);
    // console.dir(base64File);
    return this.httpClient.post(`${this.RegistrationApiBaseUrl}${this.regUrl}/UploadFile`, body, this.httpOptions())
    .pipe(
      catchError(this.errorHand)
    );
  } 

  
 //Check if serial exist
 getSernumForReg(serNum:string) : Observable<any>{
   //console.log("--------------------------------------API Address: +" + this.RegistrationApiBaseUrl + "-----------------------------------------------------");
  let body = new HttpParams();
  body = body.set('sernum', serNum);
  return this.httpClient.post(`${this.RegistrationApiBaseUrl}${this.regUrl}/CheckSernum`, body, this.httpOptions())
  .pipe(
    catchError(this.errorHand)
  );
}


// send all the data to the server in order to complete the reg
sendReg(regForm:FormGroup, regParams: RegParams) : Observable<any>{
  console.log('form data from the service:');
  console.log(regForm.value);
  console.log('reg params from the service:');
  console.dir(regParams);

  let body = new HttpParams();
  body = body.set('SerialNum', regForm.get('alarmDetails').get('SerialNum').value.toUpperCase().trim());
  body = body.set('invoiceNumber', regForm.get('alarmDetails').get('invoiceNumber').value);
  body = body.set('year', regForm.get('alarmDetails').get('year').value);
  body = body.set('month', regForm.get('alarmDetails').get('month').value);
  body = body.set('day', regForm.get('alarmDetails').get('day').value);
  body = body.set('AlarmDropTest', regForm.get('alarmDetails').get('AlarmPosition').value == "Yes" ? "Y" : "N");
  body = body.set('AlarmAudioTest', regForm.get('alarmDetails').get('AlarmTest').value== "Yes" ? "Y" : "N");
  body = body.set('firstName', regForm.get('personalDetails').get('firstName').value);
  body = body.set('lastName', regForm.get('personalDetails').get('lastName').value);
  body = body.set('email', regForm.get('personalDetails').get('email').value);
  body = body.set('address', regForm.get('personalDetails').get('address').value ?? "");
  body = body.set('city', regForm.get('personalDetails').get('city').value);
  body = body.set('zipCode', regForm.get('personalDetails').get('zipCode').value);
  let phoneNum = "+" + regForm.get('personalDetails').get('prePhone').value + "-" + regForm.get('personalDetails').get('phone').value;
  body = body.set('phone', encodeURIComponent(phoneNum));
  body = body.set('country', regForm.get('personalDetails').get('country').value);
  body = body.set('state', regForm.get('personalDetails').get('state').value);
  let poolDim = "";
  if(regForm.get('personalDetails').get('poolDimension').value == "1"){
    poolDim =  "Free Form" ;
  }
  else if(regForm.get('personalDetails').get('poolDimension').value == "0"){
    let size="";
    if(regForm.get('personalDetails').get('poolLength').value!=""){
      size= regForm.get('personalDetails').get('poolLength').value + " X " + regForm.get('personalDetails').get('poolWidth').value
    }
    poolDim =  "Rectangular " + size;
  }
  body = body.set('poolDimension', poolDim);
  body = body.set('resellerName', regForm.get('resellerDetails').get('resellerName').value);
  body = body.set('resellerCity', regForm.get('resellerDetails').get('resellerCity').value ?? "");
  body = body.set('resellerZipCode', regForm.get('resellerDetails').get('resellerZipCode').value ?? "");
  //body = body.set('popUrl', regForm.get('resellerDetails').get('proofOfPurchase').value ?? "");
  body = body.set('approveReceive', regForm.get('resellerDetails').get('approveReceive').value);
  body = body.set('Language', localStorage.getItem("currentLanguage") ?? "en");

  return this.httpClient.post(`${this.RegistrationApiBaseUrl}${this.regUrl}/reg`, body, this.httpOptions())
  .pipe(
    catchError(this.errorHand)
  );
  //res>-1 : success  
  //res=<-1 : error
}

  errorHand(error) {
    //alert("server error");
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    //throw new Error(errorMessage);
    //throw new Error();//throwError(error);
      
    return throwError(error);
    
      //return this.globHandler.handleError(error);
      //  return Promise.resolve()
      //  {
      //    throw new Error(error)};
  }

}
