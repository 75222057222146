import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http/';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {

  constructor(
    private http: HttpClient
  ) { 

  }
 
  //function to get the IP of the current user
  getIpAddress() {
    return this.http
          .get('https://api.ipify.org/?format=json')
          .pipe(
            catchError(this.handleError)
          );
  } 

  //api that provides the information of the location of the current IP
 getGEOLocation(ip) {
  let url = "https://api.ipgeolocation.io/ipgeo?apiKey=0f4e06b0721a4a2b82bce38abc80b31a&ip="+ip; 
    return this.http
          .get(url)
          .pipe(
            catchError(this.handleError)
          );
  } 

  //api that provides us the information about the selected country
  getPrePhoneByCountry(country) {
    let url = "https://restcountries.eu/rest/v2/name/"+country; 
      return this.http
            .get(url)
            .pipe(
              catchError(this.handleError)
            );
    } 


  //api that provides us the city name according to the zip code
  getCityNameByZipCode(zip) {
    let url = "https://api-adresse.data.gouv.fr/search/?q=8+bd+du+"+zip; 
      return this.http
            .get(url)
            .pipe(
              catchError(this.handleError)
            );
    } 

    //function to handle errors
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  


}
