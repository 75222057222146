import { Component, OnInit, Input , ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {HttpClient,HttpEventType,HttpErrorResponse} from "@angular/common/http/";
import { map, catchError } from "rxjs/operators";
import { RegParams } from '../../mainform.component';
import { Observable, throwError } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { bool } from 'aws-sdk/clients/signer';
import { RegistrationApiService } from  'src/app/services/registrationApi.service';



@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit {

  CurrentPicInBase64 : any;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  someMethod: () => void;

  constructor(private elementRef: ElementRef, private http: HttpClient,private RegistrationApiService: RegistrationApiService, private gtmService: GoogleTagManagerService) {
   }

  @Input() regForm: FormGroup;
  @Input() RegParams: RegParams;

  ngOnInit(): void {
   
  }


  files: any = [];
  FileIsTooBig = false;
  FileIsNotInFormat = false;
  fileExists = false;
  failedToUpload = false;
  selectedFileSrc: string;
  fileChangedStatus = 'init';
  fileFormatArray: string[]= ['pdf','PDF','jpg','JPG','JPEG','jpeg','gif','GIF','png','PNG'];
  uploadingNow = false;
  fileType: string;
  //progress: number = 0;


  //upload pop file 
  uploadFile(event) {
    if(event[0]){  //only if some file was selected by the user
      this.uploadingNow = true;
      this.files= []; //make it available to upload only one file (if we want multiple files upload option just remove this line)
        const file = event[0]; //get the file (only the first one if the user drag more then one file to the box)
        //check that the file size not above 2mb
        console.dir(file);
        this.failedToUpload = false; //reset this value to false
        //check file size (limit of 2mb)
        if (file.size > 2097152) {
          this.FileIsTooBig = true;
        }
         else {
          this.FileIsTooBig = false;
        }
        //get the type of the file
        var a= file.name.split('.');
        var fileType= a[a.length-1];
        //check that the type of the file are ok - options are ['pdf','PDF','jpg','JPG','JPEG','jpeg','gif','GIF','png','PNG']
        if(!this.fileFormatArray.includes(fileType)){
          this.FileIsNotInFormat = true;
        }
        else{
          this.FileIsNotInFormat = false;
        }
        //if size and type are ok
        if(!this.FileIsNotInFormat && !this.FileIsTooBig ) {
          this.files.push(file.name); //add the file name to the files
           this.RegistrationApiService.getBase64(file).then( 
            (data) => { 
              this.CurrentPicInBase64 =  data;
               //console.dir(data); //file in base64 string
               this.RegistrationApiService.uploadFile(data, file.name, file.type, this.regForm.get('alarmDetails').get('SerialNum').value.trim()).subscribe(value => {
                console.log("url to the uploaded file: " + value); //value = url to the uploaded file
                if(value == ""){ //if the uploaded field
                  this.uploadingNow = false; 
                  this.fileExists = false; 
                  this.failedToUpload = true; 
                  const fileInput = this.elementRef.nativeElement.querySelectorAll('#fileInput')[0];
                  fileInput.value= "";
                  this.regForm.get('resellerDetails').get('proofOfPurchase').setValue(null);
                }
                else { //upload success
                  this.uploadingNow = false; 
                  this.fileExists = true; 
                  this.fileType= file.type.split('/')[0] ; //get the file type (image or application(pdf))
                  this.regForm.get('resellerDetails').get('proofOfPurchase').setValue(value); //set the url to the file in s3 
                  //console.log(this.regForm.get('warrantyDetails').get('proofOfPurchase').value);
                }
              });
               return data;
            }
         );  
        }
        else{ //if over size limit or not in the possible format option
          this.uploadingNow = false; 
            this.fileExists = false; 
             const fileInput = this.elementRef.nativeElement.querySelectorAll('#fileInput')[0];
             fileInput.value= "";
             this.regForm.get('resellerDetails').get('proofOfPurchase').setValue(null); 
        }
    }
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.fileExists = false;
    //this.uploadingNow = false;
    const fileInput = this.elementRef.nativeElement.querySelectorAll('#fileInput')[0];
    fileInput.value= "";
    this.proofOfPurchase.setValue(null);
  }

  step3Submitted() {
    this.resellerName.markAsTouched();
    this.resellerName.updateValueAndValidity();
    this.confirmPrivacyPolicy.markAsTouched();
    this.confirmPrivacyPolicy.updateValueAndValidity();
    //this.proofOfPurchase.markAsTouched();
    //this.proofOfPurchase.updateValueAndValidity();

    if(this.regForm.get('resellerDetails').valid ){
      this.RegParams.step3Valid = true;
    }
    else{
      this.RegParams.step3Valid = false;
    }

    //google analytic
    if(this.regForm.get('resellerDetails').valid ){
      console.log("step 3 valid");
      const gtmTag = {
        event: 'continue buttons',
        eventCategory: 'continue buttons',
        eventAction: 'step 3'
      };
      this.gtmService.pushTag(gtmTag);
    }
    else{
      console.log("step 3 invalid");
      return false;
    }

  }


  //get functions
  get resellerName() {
    return this.regForm.get('resellerDetails').get('resellerName');
  }
  get resellerCity() {
    return this.regForm.get('resellerDetails').get('resellerCity');
  }
  get resellerZipCode() {
    return this.regForm.get('resellerDetails').get('resellerZipCode');
  }
  get proofOfPurchase() {
    return this.regForm.get('resellerDetails').get('proofOfPurchase');
  }
  get confirmPrivacyPolicy() {
    return this.regForm.get('resellerDetails').get('confirmPrivacyPolicy');
  }

}


