import { Component, OnInit, Input, ElementRef  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegParams } from '../../mainform.component';
import {HttpClient,HttpEventType,HttpErrorResponse} from "@angular/common/http/";
import { RegistrationApiService } from  'src/app/services/registrationApi.service';
import { bool } from 'aws-sdk/clients/signer';
import { Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { Console } from 'console';
import { AbstractControl } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CloseScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})



export class Step1Component implements OnInit {
  serNumIsExist: bool = false;
  finishCheckSerialFailed: bool = false;
  finishCheckSerialSuccess: bool = false;
  spinSernum: bool = false;
  serverError500Sernum: bool = false;
  showProductDiv: bool = false;
  toCheckSerNum: bool = false;
  serialBlockedInPriority: bool = false;
  serialAlreadyRegistered : bool = false;
  serialNumNotExist : bool = false;
  date: Date;
  hidden: boolean = true;
  datePicker: any;
  maxDate: Date;
  minDate: Date;

  constructor(private elementRef: ElementRef,  private RegistrationApiService: RegistrationApiService, private http: HttpClient, private activatedRoute: ActivatedRoute, private gtmService: GoogleTagManagerService) {
      //set max date to today and min date to 01.01 of 3 year ago 
      this.maxDate = new Date();
      const yearsAgo= (new Date().getFullYear() -3).toString();
      this.minDate = new Date("01/01/"+yearsAgo);
  }

  @Input() regForm: FormGroup;
  @Input() RegParams: RegParams;

  ngOnInit(): void {
  }

  //function by keypress from serial number input
  keyPressSerial(event: KeyboardEvent, serNum: string) {
    //console.dir(event);
    if(event.keyCode == 13){ //if enter was press we will call to 
      this.searchForSerialNumber(serNum);
      //prevent from enter to hit the continue button
      event.stopPropagation();
      event.preventDefault();
    }
    else{
      //every key press we will reset the checkSerNum variable and disabled the coupon
      this.toCheckSerNum = false;
    }
  }

  resetCheckSernum(){
    //every change serial value we will reset the checkSerNum variable and disabled the coupon
    this.toCheckSerNum = false;
    this.spinSernum = false;
    this.finishCheckSerialFailed = false;
    this.finishCheckSerialSuccess = false;
    this.serverError500Sernum = false;
  }

  //check the serial number 
  searchForSerialNumber(serNum: string){
    this.serverError500Sernum = false;
    if(serNum!=null && serNum.length>0){
    //console.log(serNum);
    this.spinSernum = true;
    //console.log('blur');
    this.toCheckSerNum = true;
   
    //will go to the service only after typing more than 8 characters
    //this.regForm.get('alarmDetails').get('SerialNum').setErrors({ 'incorrect': true}); //set initial error invalid by default
    if(serNum.length>8){
      //call the service with this serNum
      this.RegistrationApiService.getSernumForReg(serNum.trim()).subscribe(value => {
        console.dir(value);
        const a = value.toString().replace(/^"(.*)"$/, '$1').split(',');
        console.log("a[0]:" + a[0]);
        if(a[0] == "1"){ //exist
          this.serNumIsExist = true;
          this.resetSerialError();
          //console.log(this.serNumIsExist);
          this.RegParams.Part= a[1];
          this.RegParams.PartName= a[2];
          this.RegParams.PartDes= a[3]
          console.log(serNum +  "- this serNum Is Exist" );
          this.SerialNum.setErrors(null); //serNum valid, therefore we will remove errors
          console.log("serNum data: ");
          console.dir(this.RegParams);
          this.showProductDiv = true; //show Product div
          this.finishCheckSerialSuccess = true;
        }
        else{ //not 
          this.showProductDiv = false; //hide Product div
          this.resetRegParams();
          this.resetSerialError();
          this.SerialNum.setErrors({ 'incorrect': true}); //important to do this again
          this.serNumIsExist = false;
          this.finishCheckSerialFailed = true;
          if(a[0] == "-1"){
            this.serialNumNotExist = true;
            console.log( serNum + " - this serNum not exist");
          }
          else if(a[0] == "-2"){
            this.serialAlreadyRegistered = true;
            console.log( serNum + " - this serNum already registered");
          }
          else if(a[0] == "-3") { //-3 is blocked to register in priority
            this.serialBlockedInPriority = true;
            console.log( serNum + " - this serNum blocked in priority");
          }
          else{ //and if some reason we get other res i a[0] we will show error 500
            this.serverError500Sernum = true;
            console.log("Server Error 500!");
          }
        }
      },
      error => {
        this.resetSerialError();
        this.resetRegParams();
        this.serNumIsExist = false;
        this.finishCheckSerialFailed = true;
        this.serverError500Sernum = true;
        this.showProductDiv = false; //hide Product div
        console.log("!!!!!!!!!!!!!!!!Server Error 500!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }); 
   }
    else{
      this.serNumIsExist = false;
      this.finishCheckSerialFailed = true;
      this.SerialNum.setErrors({ 'incorrect': true}); //important to do this again
      console.log(this.serNumIsExist);
      console.log( serNum + " - this serNum not exist");
      this.showProductDiv = false; //hide Product div
      this.resetSerialError();
      this.serialNumNotExist = true;
      this.resetRegParams();
    }
  }
  else{
    this.serNumIsExist = false;
    this.finishCheckSerialFailed = true;
    this.spinSernum = false;
    this.showProductDiv = false; //hide Product div
    this.resetSerialError();
    this.resetRegParams();
  }
}


  //function that reset all regParams
  resetRegParams(){
    this.RegParams.PartName="";
    this.RegParams.SerialImgURL="";
    this.RegParams.PartDes= "";
    this.RegParams.Part= "";
    this.RegParams.step3Valid = false;
    this.RegParams.RegCompleted = false;

  }

  resetSerialError(){
    this.serialBlockedInPriority = false;
    this.serialNumNotExist = false;
    this.serialAlreadyRegistered = false;
  }


//function that just marked the sernum field if he is empty or null
onCheckSer(){
  if(this.SerialNum.value == "" || this.SerialNum.value == null){
    this.SerialNum.markAsTouched();
    this.SerialNum.updateValueAndValidity(); 
  }
  // else{
  //   this.searchForSerialNumber(this.SerialNum.value);
  // }
}


//set the date
setDate(dateStr: string) {
  console.log(dateStr);
  const CurrentLng = localStorage.getItem("currentLanguage");
  console.log("Selected language: " +CurrentLng);
  let year: number;
  let month: number;
  let day: number;
  let dateArr : string[];
  if (dateStr != null && dateStr != "") {
    if(CurrentLng=='en' || !CurrentLng){ //"mm/dd/yyyy" or nothing in localStorage - we will go to english format
      dateArr = dateStr.split('/');
      year= Number(dateArr[2]);
      month= Number(dateArr[0]);
      day= Number(dateArr[1]);
    }
    else{ //"dd/mm/yyyy" or "dd.mm.yyyy"
      if(dateStr.indexOf("/") > -1){
        dateArr = dateStr.split('/');
      }
      else{
        dateArr = dateStr.split('.');
      }
      year= Number(dateArr[2]);
      month= Number(dateArr[1]);
      day= Number(dateArr[0]);
    }
    console.log("year: " + year);
    console.log("month: " + month);
    console.log("day: " + day);
    //set the value into the form fields
    this.regForm.get('alarmDetails').get('year').setValue(year);
    this.regForm.get('alarmDetails').get('month').setValue(month);
    this.regForm.get('alarmDetails').get('day').setValue(day);

    //this.date = new Date(dateStr);
    //console.log(this.date);
  }
}


  step1Submitted() {
    this.SerialNum.markAsTouched();
    this.SerialNum.updateValueAndValidity();
    this.purchaseDate.markAsTouched();
    this.purchaseDate.updateValueAndValidity();
    this.invoiceNumber.markAsTouched();
    this.invoiceNumber.updateValueAndValidity();
    this.AlarmPosition.markAsTouched();
    this.AlarmPosition.updateValueAndValidity();
    this.AlarmTest.markAsTouched();
    this.AlarmTest.updateValueAndValidity();

    //scrolling to errors
    //if the user didnt enter a valid sernum 
    if(this.SerialNum.invalid || !this.serNumIsExist){
      const MyAlarmTitle = this.elementRef.nativeElement.querySelectorAll('#MyAlarmTitle')[0];
      MyAlarmTitle.scrollIntoView({behavior: 'smooth'}); //scroll to the question 
    }

    //google analytic
      if(this.regForm.get('alarmDetails').valid && this.serNumIsExist && this.toCheckSerNum ){
          console.log("step 1 valid");
          const gtmTag = {
            event: 'continue buttons',
            eventCategory: 'continue buttons',
            eventAction: 'step 1'
          };
          this.gtmService.pushTag(gtmTag);
      }
      else{
        console.log("step 1 invalid");
        return false;
      }
  } //end of submit function

  //function that generate code of 6 digit for second reg process 
  // generateCode(){
  //   //generate code of 6 digit
  //   //let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
  //   let possible = "1234567890";
  //   const lengthOfCode = 6;
  //   let code = "";
  //   for (let i = 0; i < lengthOfCode; i++) {
  //     code += possible.charAt(Math.floor(Math.random() * possible.length));
  //   }
  //   //insert the code to the session
  //   sessionStorage.setItem('codeForSecondReg', code);
  // }


//get functions
  get SerialNum() {
    return this.regForm.get('alarmDetails').get('SerialNum');
  }
  get purchaseDate() {
    return this.regForm.get('alarmDetails').get('purchaseDate');
  }
  get invoiceNumber() {
    return this.regForm.get('alarmDetails').get('invoiceNumber');
  }
  get AlarmPosition() {
    return this.regForm.get('alarmDetails').get('AlarmPosition');
  }
  get AlarmTest() {
    return this.regForm.get('alarmDetails').get('AlarmTest');
  }



  
}
