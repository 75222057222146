
import { NgModule} from '@angular/core';
import { AngularMaterialModule } from './AngularMaterialModule/angular-material.module';
// import { AngularFontAModule } from './AngularFontAwesomeModule/font-awesome.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DatePickerModule } from './date-picker/date-picker.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({

  imports: [
    AngularMaterialModule,
    // AngularFontAModule,
    FlexLayoutModule,
    // DatePickerModule,
    MatSnackBarModule

  ],
  exports:[
    AngularMaterialModule,
    // AngularFontAModule,
    FlexLayoutModule,
    DatePickerModule,
    MatSnackBarModule
  ]
})
export class SharedModule { }
