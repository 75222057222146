
<div class="container introContainer" style="min-height: 250px;">
    <h2 class="introStyle">{{'introTitle' | translate }}</h2>
      <!-- <h3 style="margin-bottom: 5px;">{{'introSubTitle' | translate }}</h3> -->

      <!-- <p class="flex"><mat-icon class="mtIcon">alarm</mat-icon>  
        <span class="textNextToIcon"> {{'introP1' | translate }}</span>
      </p>
      <p class="flex"><mat-icon class="mtIcon">money_off</mat-icon>  
        <span class="textNextToIcon"> {{'introP2' | translate }}</span>
      </p>
      <p class="flex" id="wifiP"><mat-icon class="mtIcon">wifi</mat-icon>  
        <span class="textNextToIcon"> {{'introP3' | translate }}</span>
      </p>

      <h4>{{'introLastSen' | translate }}</h4> -->
  </div>

