<div id="alarm-details" [formGroup]="regForm" style="margin-top: 15px;">
    <h2 id="MyAlarmTitle">{{'MyAlarm' | translate }}</h2>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group col-md-12" formGroupName="alarmDetails">
                <label class="QueTitle">* {{'SerialNumber' | translate }}</label>
                <!-- <input type="text" #serNum class="serNumAndCouponInputs form-control" formControlName="SerialNum" maxlength="12"  (ngModelChange)="searchForSerialNumber(serNum.value)"> -->
                <div class="input-group">
                    <input type="text" #serNum class="serNumAndCouponInputs form-control" [ngClass]="'Variable_input-group' | translate" formControlName="SerialNum" maxlength="9" (blur)="searchForSerialNumber(serNum.value)" (ngModelChange)="resetCheckSernum()" (keypress)="keyPressSerial($event,serNum.value)">
                    <span class="input-group-addon" [ngClass]="'Variable_input-group-addon' | translate"><i class="material-icons" [ngClass]="(!spinSernum || serNumIsExist || regForm.get('alarmDetails').get('SerialNum').errors) || serverError500Sernum ? '' :'spin'" > search</i></span>
                </div>
                <!--serNum exist-->
                <span *ngIf="serNumIsExist && toCheckSerNum && finishCheckSerialSuccess" class="successMsg flex">
                    <i class="material-icons">check</i>
                    <span class="textNextToIcon">{{'SerialNumberValid' | translate }}</span> 
                 </span>
                  <!--server error-->
                <span *ngIf="serverError500Sernum" class="errorMsg flex">
                    <i class="material-icons">close</i>
                    <span class="textNextToIcon">{{'ServerError' | translate }}</span> 
                 </span>
                <!-- serNum not exist-->
                <!-- <span *ngIf="!serNumIsExist" class="errorMsg">
                  <i class="material-icons">close</i> Serial number not exist!
                </span>  -->
                <!--error msg-->
                <span *ngIf="SerialNum.invalid && (SerialNum.dirty || SerialNum.touched)" class="errorMsg">
                    <span *ngIf="SerialNum.errors.required">{{'Required' | translate }}</span>
                    <span *ngIf="SerialNum.errors && finishCheckSerialFailed && !(SerialNum.errors.required)">
                        <span *ngIf="serialNumNotExist"> {{'SerialNumberNotExist' | translate }} </span>
                        <span *ngIf="serialAlreadyRegistered"> {{'serialAlreadyRegistered' | translate }} </span>
                        <span *ngIf="serialBlockedInPriority"> {{'serialBlockedInPriority' | translate }} </span>

                        <p>{{'pleaseTryAgainOrContactUs' | translate }} <a href="mailto:support@maytronics.fr"> support@maytronics.fr </a>  </p>
                    </span>
                </span>

                <p *ngIf="!serNumIsExist || !toCheckSerNum" class="flex"> 
                    <img class="" src="../../../../../assets/icons/Info circled.png" alt="" data-target="#SeeSerialNumModal" data-toggle="modal"  style="cursor:pointer;max-height: 24px;">
                    <!-- <i class="material-icons" style="cursor:pointer;color:#58c8df" data-target="#SeeSerialNumModal" data-toggle="modal">info</i> -->
                   <span class="textNextToIcon"> {{'WhereToFindMySerialNumber' | translate }}</span>
                 </p>
            </div>
            <div class="robotBox center"> 
                <div *ngIf="!serNumIsExist || !toCheckSerNum" class="col-md-12 center">
                    <img src="assets/images/alarmPicGrey.jpg" class="alarmIconPic" style="opacity: 0.3;" id="robotIconImg" alt="">
                    <div class="partAndDesDivEmpty">
                        <span style="font-weight: 500;">{{'AlarmPartDescription' | translate }}</span> <br>
                        <span>{{'AlarmPartName' | translate }}</span>
                    </div>
                </div> 
                <div *ngIf="serNumIsExist && toCheckSerNum" class="col-md-12 center">
                        <!-- <img src="{{RegParams.SerialImgURL}}" id="robotImg" alt=""> -->
                        <img src="assets/images/alarmPic.jpg" class="alarmIconPic"  id="robotImg" alt="">
                        <div class="partAndDesDiv">
                            <span style="font-weight: 500;">{{RegParams.PartDes}}</span> <br>
                            <span> {{RegParams.PartName}}</span>
                        </div>
                </div>
            </div>
        </div>

        <!--Product section-->
    <div  class="col-md-6" id="ProductDiv" *ngIf="this.showProductDiv">

         <!--invoice number-->
         <div class="form-group col-md-12" formGroupName="alarmDetails">
            <label class="QueTitle">* {{'InvoiceNumber' | translate }}</label>
               <input type="text" class="form-control" formControlName="invoiceNumber" maxlength="15">
                    <p *ngIf="invoiceNumber.invalid && (invoiceNumber.dirty || invoiceNumber.touched)" class="errorMsg">
                        <span *ngIf="invoiceNumber.errors.required">{{'Required' | translate }}</span>
                    </p>
            </div>

        <!--purchase date-->
        <div class="form-group col-md-12" formGroupName="alarmDetails">
            <label class="QueTitle">* {{'PurchaseDate' | translate }} ({{'DateFormat' | translate }})</label><br>
            
            <div class="input-group" >
                    <input matTooltip="{{'calenderClick' | translate }}" matTooltipPosition="left" aria-label="Button that displays a tooltip when focused or hovered over"
                     class="form-control" matInput [matDatepicker]="picker" id="purDate" formControlName="purchaseDate" style="text-align: left;background-color: white"
                    [max]="maxDate" [min]="minDate" #pp (dateChange)="setDate(pp.value)" readonly autocomplete="off" placeholder="">
                        <span class="input-group-addon" style="margin-top:-1px;max-width: 49px;padding: 0px 10px;" [ngClass]="'Variable_input-group-addon' | translate">
                            <mat-datepicker-toggle class="matDatePickerStyle" matSuffix [for]="picker">
                                <i class="material-icons"> calendar_today</i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                      </span>
                </div>

              <!--error msg-->
            <p *ngIf="purchaseDate.invalid && (purchaseDate.dirty || purchaseDate.touched)" class="errorMsg">
                <span *ngIf="purchaseDate.errors.required">{{'Required' | translate }}</span>
            </p>
            <!--End error msg-->
        </div>


      <!--AlarmPosition-->
      <div class="form-group col-md-12" formGroupName="alarmDetails">
        <p class="flex"> 
            <!-- <i class="material-icons" style="color:#58c8df">info</i> -->
            <img class="" src="../../../../../assets/icons/Info circled.png" alt="" style="max-height: 24px;">
           <span class="textNextToIcon"> {{'AlarmTestExp' | translate }}</span>
         </p>
            <label class="QueTitle">* {{'AlarmPosition' | translate }}</label>
            <div class="input-group" style="display: block;">
                <input id="AlarmPositionNo" value="No"  type="radio" formControlName="AlarmPosition"> <span style="margin-left: 5px;"> {{'No' | translate }}</span>
                <input id="AlarmPositionYes" value="Yes"  type="radio" formControlName="AlarmPosition" style="margin-left: 35px;"> <span style="margin-left: 5px;"> {{'Yes' | translate }}</span>
            </div>
                <!--error msg-->
                <p *ngIf="AlarmPosition.invalid && (AlarmPosition.dirty || AlarmPosition.touched)" class="errorMsg">
                    <span *ngIf="AlarmPosition.errors.required">{{'Required' | translate }}</span>
                </p>
         <!--End error msg-->
     </div>

      <!--AlarmTest-->
      <div class="form-group col-md-12" formGroupName="alarmDetails">
        <label class="QueTitle">* {{'AlarmTest' | translate }}</label>
        <!-- <div class="input-group" style="display: block;">
            <input value="I can hear" type="radio" formControlName="AlarmTest"> <label style="margin-left: 5px;"> {{'AlarmTestHear' | translate }}</label>
            <input value="I can not hear" type="radio" formControlName="AlarmTest" style="margin-left: 35px;"> <label style="margin-left: 5px;"> {{'AlarmTestNotHear' | translate }}</label>
        </div> -->
        <div class="testAlarmDiv">
          <div class="input-group" style="display: block;">
            <input id="AlarmTestNo" value="Yes" type="radio" formControlName="AlarmTest"> <span style="margin-left: 5px;"> {{'AlarmTestHear' | translate }}</span>
        </div>
        <div class="input-group" style="display: block;">
            <input id="AlarmTestYes" value="No" type="radio" formControlName="AlarmTest" class="testAlarmDivSecondOption"> <span style="margin-left: 5px;"> {{'AlarmTestNotHear' | translate }}</span>
        </div>   
        </div>
        
            <!--error msg-->
            <p *ngIf="AlarmTest.invalid && (AlarmTest.dirty || AlarmTest.touched)" class="errorMsg">
                <span *ngIf="AlarmTest.errors.required">{{'Required' | translate }}</span>
            </p>
     <!--End error msg-->
 </div>



    </div>
 </div>
</div>
<br>

<!--button to check serial exist, the check sernum function working on blur. this button just make sure that some serial number was entered-->
<div class="center">
    <button *ngIf="!serNumIsExist || !toCheckSerNum"  id="checkSer"  (click)="onCheckSer()" mat-button>{{'CheckSerial' | translate }}</button>
</div>

<!--submit button that will be shown only if sernum exist -->
<div class="center">
    <button *ngIf="serNumIsExist && toCheckSerNum" class="btnNext" id="step1NextBtn"  mat-button matStepperNext (click)="step1Submitted()">{{'Continue' | translate }}</button>
</div>



<!--Modal that explain where to find the serial number of the robot-->
<div class="modal fade warranty-info" id="SeeSerialNumModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div class="wpb_wrapper" style="padding-left: 15px; padding-right: 15px;">
                    <h3 style="font-size: 25px;color: #000000;line-height: 30px;text-align: center;" class="vc_custom_heading">{{'WhereToFindMySerialNumberTitle' | translate }}</h3>
                    <h4 style="text-align: center;">{{'WhereToFindMySerialNumberSubTitle' | translate }}</h4>
                    <hr style="margin-top: 0px; border-top: 1px solid  #E9E7E7;">
                    
                    <div class="wpb_single_image wpb_content_element vc_align_center">
                        <div class="row" style="padding-bottom: 20px;">
                            <div class="col-md-3 marginTopForMobile" style="text-align:center;">
                                <p>Aqualarm</p>
                                <img src="assets/images/alarms/Aqualarm.png" class="imgModal" alt="">
                            </div>
                            <div class="col-md-3 marginTopForMobile" style="text-align:center;">
                                <p>Espio</p>
                                <img src="assets/images/alarms/Espio.png" class="imgModal" alt="">
                            </div>
                            <div class="col-md-3 marginTopForMobile" style="text-align:center;">
                                <p>Sensor Premium</p>
                                <img src="assets/images/alarms/Sensor Premium.png" class="imgModal" alt="">
                            </div>
                            <div class="col-md-3 marginTopForMobile" style="text-align:center;">
                                <p>Precisio</p>
                                <img src="assets/images/alarms/Precisio.png" class="imgModal" alt="">
                            </div>
                        </div>
                        <hr style="margin-top: 0px; border-top: 1px solid #E9E7E7;">
                        <div>
                            <p>
                                {{'WhereToFindMySerialNumberSupport' | translate }} 
                                <a target="a_blank" href="{{'CustomersSupportLink' | translate }}"> {{'MaytronicsCustomersSupport' | translate }} </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

