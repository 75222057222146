
<!--show this div if the form didnt submit yet and step 3 is valid-->
<div *ngIf="!formSubmitted && this.RegParams.step3Valid" class="center" style="margin-top: 30px;">
    <h2 style="margin-bottom: 0px;">{{'ConfirmRegistration' | translate }}</h2>
    <br/>
       <p>{{'RegSummary' | translate }} </p>
        <div class="product-card small">
            <div class="card-block toptitleDiv" style="max-height:185px;cursor:default">
                <h4 class="card-title center toptitleTxt">
                    <span style="display: inline-flex;">
                        <!-- <i class="material-icons">person</i> -->
                        <img class="" src="../../../../../assets/icons/User.png" alt="">
                         <b class="padLeft"> {{firstName.value | uppercase}}  {{lastName.value | uppercase}}</b> 
                    </span>
                    <br>
                    <span style="display: inline-flex;">
                        <!-- <i class="material-icons">mail</i> -->
                        <img class="" src="../../../../../assets/icons/Mail.png" alt="">
                        <b class="padLeft"> {{email.value}}</b>
                    </span>
                </h4>
            </div>
            <div class="pad">
                  <!-- <img class="img img-responsive" src="{{RegParams.SerialImgURL}}" onerror="this.src = `https://mpp-images.s3.eu-central-1.amazonaws.com/Products/0.png`;" /> -->
                  <img class="img img-responsive" src="assets/images/alarmPic.jpg" class="alarmIconPic" />
            </div>
            <div class="card-block model-thumb whiteBack">
                <p class="card-title pad">
                    {{RegParams.PartDes}} <br>
                    {{RegParams.PartName}}  <br>
                    {{regForm.get('alarmDetails').get('SerialNum').value | uppercase}} 
                    <!-- {{SerialNum.value | uppercase}}  -->
                </p>
            </div>
            <div class="card-block model-thumb greyBack">
                <p class="card-title" style="margin-bottom: 0px;">
                    <span style="display: inline-flex;">
                         <!-- <i class="material-icons">calendar_today</i> -->
                         <img class="" src="assets/icons/Calendar.png" alt="">
                        <b class="padLeft" [ngStyle]="{'display':'Variable_DateFormatUS' | translate}"> {{regForm.get('alarmDetails').get('purchaseDate').value | date:'longDate'}} </b>
                        <b class="padLeft" [ngStyle]="{'display':'Variable_DateFormatOther' | translate}"> {{regForm.get('alarmDetails').get('day').value}}{{'Variable_DateSplit' | translate }}{{regForm.get('alarmDetails').get('month').value}}{{'Variable_DateSplit' | translate }}{{regForm.get('alarmDetails').get('year').value}}</b>         
                    </span>
                    <br>
                </p>
            </div>
        </div>


    <!--buttons-->
    <div class="center margPC">
        <button  id="step4BackBtn" [ngClass]="'Variable_btnBackClass' | translate"  mat-button matStepperPrevious><i class="material-icons">{{'Variable_arrow_ios' | translate }}</i> {{'Back' | translate }}</button>
        &nbsp;
        <button [ngClass]="'Variable_btnSubmitClass' | translate" id="submitBtn" mat-button type="button" (click)="submit()">{{'Submit' | translate }}</button>
    </div>
</div>




<!--div that will be appear during the Process-->
<div *ngIf="formSubmitted && !regSuccess && !regFailed" class="center" style="margin-top: 30px;">
    <h1>{{'InProcess' | translate }}</h1>
    <h3>{{'WorkingOnCompletingRegistration' | translate }} </h3>
    <img src="assets/icons/maytronicsNewLoader.gif" alt="" style="max-width: 150px;">
</div>


<!--Reg Results-->
<div *ngIf="formSubmitted && regSuccess" class="center" style="margin-top: 30px;">
    <h1>{{'Congratulations' | translate }}</h1>
    <h3>{{firstName.value}}  {{lastName.value}}</h3>
    <h2>{{'alarmRegistered' | translate }} </h2>

    <!--success msg-->
    <div>
        {{'WelcomeToMaytronicsFamily' | translate }} <br>
        {{'YourWarrantyRegistrationHasBeenSubmitted' | translate }} 
    </div>

</div>

<div *ngIf="formSubmitted && !regSuccess && regFailed" class="errorMsg center" style="margin-top:30px">
   <i class="material-icons">cancel</i> 
   <h3 *ngIf="ServerError">{{'ServerError' | translate }}</h3>
   <h3> {{'RegError' | translate }}</h3> 
</div>

