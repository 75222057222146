
<div class="col-md-12 row headHeader" style="background-color: #000000;">
    <div class="col-md-9 headDiv" style="padding-right: 0px;">
        <!--Main Title + Logo-->
        <app-toolbar></app-toolbar>
    </div>

    <div class="col-md-3">
        <div class="langViv" style="margin-top: 10px;">
            <img src= {{flagSelected}}>
            <!-- <img width="35" height="25" style="padding-right: 10px;" src= {{langGlobalIcon}}> -->
                <mat-select class="langSelect" #langSelect (selectionChange)="changeLang(langSelect.value)" [value]="langSelected" style="color: #FFFFFF;">
                    <mat-option class="langOption" *ngFor="let lang of languageList" [value]="lang.value">
                        <img style="padding-right: 10px;" src={{lang.flag}}>
                        <span class="">{{lang.viewValue}}</span>
                    </mat-option>
                </mat-select>
        </div>
    </div>  
</div>

