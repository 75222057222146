<div id="reseller-details" [formGroup]="regForm" style="margin-top: 15px;">
    <h2 id="registerTitle">{{'resellerInfo' | translate }}</h2>

<div class="row">
    <div class="col-md-6">  
        <div class="form-group col-md-12" formGroupName="resellerDetails">
            <label><span>*</span> {{'resellerName' | translate }}</label>
            <input type="text" class="form-control" formControlName="resellerName" maxlength="50">
             <!--error msg-->
             <div>
                <p *ngIf="resellerName.invalid && (resellerName.dirty || resellerName.touched)" class="errorMsg">
                    <span *ngIf="resellerName.errors.required">{{'Required' | translate }}</span>
                </p>
            </div>
            <!--End error msg-->
          </div>
    
        <div class="form-group col-md-12" formGroupName="resellerDetails">
            <label>{{'resellerCity' | translate }}</label>
            <input type="text" class="form-control" formControlName="resellerCity" maxlength="30">
          </div>

          <div class="form-group col-md-12" formGroupName="resellerDetails">
            <label>{{'resellerZipCode' | translate }}</label>
            <input type="text" class="form-control" formControlName="resellerZipCode" maxlength="10">
          </div>
    </div>

    <div class="col-md-6" style="margin-top: 30px;">
      <!--ngIf = false because priority didn't finish-->
      <div id="proofDiv" *ngIf="false" class="form-group col-md-12" formGroupName="resellerDetails" >
        <label><span>* </span> {{'ProofOfPurchase' | translate }}</label>
        <div *ngIf="!RegParams.RegCompleted" class="uploadfilecontainer" (click)="fileInput.click();" appDragDrop (onFileDropped)="uploadFile($event);">
          <div class="center" style="margin-top: 20px;">
              <button class="btnUpload" mat-button style="display: inline-flex;">  
                <i class="material-icons">upload_file</i> {{'UploadYourReceipt' | translate }}
              </button>
                <p style="padding:1px; padding-top: 10px;">{{'PossibleFormats' | translate }}: PDF/PNG/JPG/JPEG/GIF <br>
                  {{'MaxFileSize' | translate }}: 2MB <br>
                  {{'YouCanAlsoDragAndDropYourFileHere' | translate }}
                </p>

                <img *ngIf="uploadingNow" class="spinner" src="assets/icons/maytronicsNewLoader.gif" />

                <!--display the image by the URL from s3-->
                <!-- <img *ngIf="fileExists && !uploadingNow && fileType=='image'" class="fileImg" src="{{regForm.get('resellerDetails').get('proofOfPurchase').value}}" /> -->
                
                <!--display the image by base64-->
                <img *ngIf="fileExists && !uploadingNow && fileType=='image'" class="fileImg" src="{{CurrentPicInBase64}}" />
                <!--if pdf and not a image we will show a pdf icon-->
                <img *ngIf="fileExists && !uploadingNow && fileType=='application'" class="fileImg" src="assets/icons/pdfIcon.png" />
         </div>
        <input  hidden type="file" id="fileInput" #fileInput (change)="uploadFile($event.target.files);" accept= "image/*,application/pdf">
      </div>

      <div *ngIf="fileExists && !uploadingNow">
        <div class="files-list" *ngFor="let file of files;let i=index">
            <p style="margin:5px 10px">{{ file }} </p>
            <button *ngIf="!RegParams.RegCompleted" class="delete-file" (click)="deleteAttachment(i)">
              <i class="material-icons">delete</i>
            </button>
          </div>
      </div>
      <!--if reg completed and the user went back to this step- either he will see the file name only or massage that no file has been uploaded -->
      <div *ngIf="RegParams.RegCompleted && !fileExists">
        <div class="files-list" >
            <p style="margin-left: 10px;">{{'NoFileHasBeenUploaded' | translate }}</p>
          </div>
      </div>
        <div *ngIf="failedToUpload"
            class="alert alert-danger mt-2">
            {{'FailedUploadFile' | translate }}
        </div>
        <div [hidden]="!FileIsTooBig"
            class="alert alert-danger mt-2">
            {{'FileTooBig' | translate }}
        </div>
        <div [hidden]="!FileIsNotInFormat"
        class="alert alert-danger mt-2">
        {{'FileNotInFormat' | translate }}
    </div>
        <!--error msg-->
        <div style="margin-left: 15px;">
            <p *ngIf="proofOfPurchase.invalid && (proofOfPurchase.dirty || proofOfPurchase.touched)" class="errorMsg">
                <span *ngIf="proofOfPurchase.errors.required">{{'Required' | translate }}</span>
            </p>
        </div>
        <!--End error msg-->
    </div>



          <!--Receive Product Information - checkbox for all cases-->
         <div class="form-group col-md-12 flex" formGroupName="resellerDetails">
            <input type="checkbox" class="approveRecive" value="" formControlName="approveReceive">
                <span style="padding:0px 10px;margin-top:-4px">{{'CheckboxReceiveProductInformation' | translate }}</span>
        </div>

   <!-- privacy Policy & terms and condition - mandatory checkbox for all cases-->
   <div class="form-group col-md-12 flex" formGroupName="resellerDetails">
    <input type="checkbox" class="approveRecive" value="" formControlName="confirmPrivacyPolicy" [ngClass]="'Variable_marginConfirmPrivacyPolicy' | translate">
    <!------------------------------------------uniq text for each language because of the texts--------------------------------------->
  <!-- <span *ngIf="('currentLang' | translate) == 'en'"> * {{'IHaveReadAndAcceptedThe' | translate }} <a target="_blank" href="{{'privacyPolicyUrl' | translate }}">{{'privacyPolicy' | translate }}</a></span> -->
  <span *ngIf="('CurrentLang' | translate) == 'en'"> 
    * I have read and I agree to Maytronics <a target="_blank" href="{{'termsOfServiceUrl' | translate }}">Terms & Conditions</a> and <a target="_blank" href="{{'privacyPolicyUrl' | translate }}">Privacy Policy</a>
  </span>
  <span *ngIf="('CurrentLang' | translate) == 'fr'"> 
    * J’ai lu et j’accepte les <a target="_blank" href="{{'termsOfServiceUrl' | translate }}">termes et conditions</a> de Maytronics et la <a target="_blank" href="{{'privacyPolicyUrl' | translate }}">politique de données personnelles. </a>
  </span>   
</div>
<!--error msg-->
<span *ngIf="confirmPrivacyPolicy.invalid && (confirmPrivacyPolicy.dirty || confirmPrivacyPolicy.touched)" class="errorMsg" [ngClass]="'Variable_marginErrorInConfirmPrivacyPolicy' | translate">
<span *ngIf="confirmPrivacyPolicy.errors">{{'Required' | translate }}</span>
</span>
<!--End error msg-->
    </div>
</div>

  <div class="center margPC">
    <button  id="step3BackBtn" [ngClass]="'Variable_btnBackClass' | translate"  mat-button matStepperPrevious><i class="material-icons">{{'Variable_arrow_ios' | translate }}</i> {{'Back' | translate }}</button>
    &nbsp;
    <button id="step3NextBtn" class="btnNext" mat-button matStepperNext (click)="step3Submitted()">{{'Continue' | translate }}</button>
  </div>
