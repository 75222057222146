<div class="tol container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div role="banner">
                <img class="logo" src="../../../assets/logos/logoWhite.png" alt="">
                <span class="mainTitle">{{'MainTitle' | translate }}</span>
                <div class="spacer"></div>
            </div>
        </div>
    </div>


</div>




