import { Component, AfterViewInit, AfterContentInit , AfterViewChecked, Renderer2 } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewChecked{
  title = 'Registration';
  constructor(private renderer: Renderer2) {

  }

  //after all the components ready we will remove the loading icon
  ngAfterViewChecked() {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
    
  }

}
