
import { Component, OnInit, Input, ElementRef  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegParams } from '../../mainform.component';
import { RegistrationApiService } from  'src/app/services/registrationApi.service';
import { CountriesAndStateService } from  'src/app/services/countries-and-state.service';
import {HttpClient,HttpEventType,HttpErrorResponse} from "@angular/common/http/";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { VisitorsService } from 'src/app/services/visitors.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {

  countriesList: string[];
  s_a = new Array();
  statesList: string[];
  countryControl = new FormControl(null, Validators.required); //for the autocomplete
  stateControl = new FormControl(null, Validators.required); //for the autocomplete
  filteredCountries: Observable<string[]>; //for the autocomplete
  filteredStates: Observable<string[]>;//for the autocomplete
  canEnterState: boolean = false;
  
  toCheckTheEmail: boolean = false;
  EmailIsValid: boolean = false;
  PhoneNumberPattern = "^[0-9.]{9,20}"; //9-20 digit or dot - (french use dot after each 2 digit in phones numbers)
  PrePhoneNumberPattern = "^[0-9]{2,3}"; //2-3 digit only
  PoolSizePattern = "^[0-9.]{0,3}"; //2-3 digit only
  freeForm : boolean = true;


  constructor(private elementRef: ElementRef , private registrationApiService: RegistrationApiService, private http: HttpClient, private countryAndStateService : CountriesAndStateService, private gtmService: GoogleTagManagerService,  private visitorsService:VisitorsService) {
    this.countriesList = countryAndStateService.getCountriesList(); //get the countries list from the service
    this.setAutoCompleteCountriesListData();
    this.stateControl.disable();
  }

  @Input() regForm: FormGroup;
  @Input() RegParams: RegParams;

  ngOnInit(): void {
     this.changeCountryOutsideTheForm(this.country.value);
  }

  //first reg function
    //check the email
  checkEmail(emailVal: string){
  //will go to the service to check validation in server side only after email is valid on client side
  if(this.email.valid){
    //console.log("go to server to check");
    this.toCheckTheEmail = true;
    this.registrationApiService.isEmailValid(emailVal).subscribe(value => {
      console.log("res from email service: " + value);
      if(value.toString()=="N"){
        this.EmailIsValid = false;
        this.email.setErrors({ 'incorrect': true});
        //console.log("email not valid!" );
      }
      else if(value.toString()=="Y" &&  !this.email.errors){
        this.EmailIsValid = true;
        this.email.setErrors(null);
        //console.log("email valid" );
      }
    },
    error => {
      console.log("!!!!!!!!!!!!!!!!Server Error 500!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    }); 
  }
  else{
    this.toCheckTheEmail = false;
    this.EmailIsValid = false;
    this.email.setErrors({ 'incorrect': true});
  }
 }

 changePoolDim(val: number){
   console.log(val);
    if(val == 1){
      //console.log("dis");
      this.poolLength.setValue("");
      this.poolWidth.setValue("");
      this.poolLength.disable();
      this.poolWidth.disable();
      this.poolLength.updateValueAndValidity();
      this.poolWidth.updateValueAndValidity();
    }
    else{
      //console.log("enb");
      this.poolLength.enable();
      this.poolWidth.enable();
      this.poolLength.updateValueAndValidity();
      this.poolWidth.updateValueAndValidity();
    }
 }

//  checkCode(code: string){
//    let validCode=  sessionStorage.getItem('codeForSecondReg');
//    if(code.trim() == validCode){
//     this.showPersonalInfoDiv= true;
//     this.regForm.get('personalDetails').get('codeForSecondReg').setErrors(null);
//    }
//    else{
//     this.showPersonalInfoDiv= false;
//     this.regForm.get('personalDetails').get('codeForSecondReg').setErrors({'incorrect': true});
//    }
//  }

  //  //function by keypress from code input - listening to enter key
  //  keyPressCode(event: KeyboardEvent, code: string) {
  //     //console.dir(event);
  //     if(event.keyCode == 13){ //if enter was press we will call to 
  //       this.checkCode(code);
  //       //prevent from enter to hit the continue button
  //       event.stopPropagation();
  //       event.preventDefault();
  //     }
  //   }


  //autocomplete
  setAutoCompleteCountriesListData() {
    this.filteredCountries = this.countryControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value),
        map(value => value ? this._filter(value) : this.countriesList.slice())
      );
  }

  //function that in charge of the key input display after choosing a specific key  
  displayFnCountry(country: string): string {
    //console.log("displayFnCountry:" + country);
    return country ?? '';
  }
  //autocomplete function for key selection
  private _filter(str: string): string[] {
    const filterValue = str.toLowerCase();
    //console.log("filterVal: " + filterValue);
    return this.countriesList.filter(option => (option.toLowerCase()).includes(filterValue));
  }

  //every keypress on the key input field will reset the selection - selection is available only by clicking on the option inside the dropdown 
  resetCountry() {
    //console.log("resetCountry");
    this.country.setValue(null); //set the key obj to null
    this.resetState();
    this.stateControl.setValue('');
    this.stateControl.disable();
    this.statesList = [];
  }

  //not in use- function that delete the value in the key input and reset the key object
  resetCountryInput() {
    this.resetCountry();
    this.countryControl.setValue('');
    this.country.setValue(null);
    //console.dir(this.country.value);
    //reset the state filed because he depends in the key selection
    this.state.disable();
    this.canEnterState = false;
    this.state.setValue(null); //set the value to null
  }

  //change country and set the state list according to the selected country
  changeCountry(country: number, event: any) {
    if (event.isUserInput) {    // ignore on deselection of the previous option
      this.resetState();
      this.stateControl.setValue('');
      //console.log("changeCountry:");
      //console.log("country value:" + country);
      const c = (element) => element == country;
      //console.log("c: "+ c);
      const i = this.countriesList.findIndex(c); // find the index of this country in the array
      //console.log("i: "+ i);
      var cou = this.countriesList[i];
      //console.log("selected country: " + cou);
      this.country.setValue(cou);
      //get the states list of this country from the service
      this.statesList = this.countryAndStateService.getStatesListByCountry(i + 1).split('|');//add 1 to get the right index and convert this string to array by split it by | - this will be the statesList
      this.setAutoCompleteStatesListData();
      this.stateControl.enable();
      this.canEnterState = true;
      //console.log(this.statesList);
    }
  }

   //in use by default to set FR 
   changeCountryOutsideTheForm(country: number) {
    this.resetState();
    this.stateControl.setValue('');
    //console.log("changeCountry:");
    //console.log("country value:" + country);
    const c = (element) => element == country;
    //console.log("c: "+ c);
    const i = this.countriesList.findIndex(c); // find the index of this country in the array
    //console.log("i: "+ i);
    var cou = this.countriesList[i];
    //console.log("selected country: " + cou);
    this.country.setValue(cou);
    this.countryControl.setValue(cou);
    //get the states list of this country from the service
    this.statesList = this.countryAndStateService.getStatesListByCountry(i + 1).split('|');//add 1 to get the right index and convert this string to array by split it by | - this will be the statesList
    this.setAutoCompleteStatesListData();
    this.stateControl.enable();
    this.canEnterState = true;
    //console.log(this.statesList);
}

  setAutoCompleteStatesListData() {
    this.filteredStates = this.stateControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value),
        map(value => value ? this._filterState(value) : this.statesList.slice())
      );
  }
  //function that in charge of the key input display after choosing a specific key  
  displayFnState(state: string): string {
    //console.log("displayFnState:" + state);
    return state ?? '';
  }
  //autocomplete function for key selection
  private _filterState(str: string): string[] {
    const filterValue = str.toLowerCase();
    //console.log("filterVal: " + filterValue);
    return this.statesList.filter(option => (option.toLowerCase()).includes(filterValue));
  }

  //every keypress on the key input field will reset the selection - selection is available only by clicking on the option inside the dropdown 
  resetState() {
    //console.log("resetState");
    this.state.setValue(null); //set the key obj to null
  }

  //set value to state 
  changeState(s: string, event: any) {
    if (event.isUserInput) {    // ignore on deselection of the previous option){
      //console.log("selected state: " + s);
      this.state.setValue(s);
    }
  }

  step2Submitted() {   
    this.firstName.markAsTouched();
    this.firstName.updateValueAndValidity();
    this.lastName.markAsTouched();
    this.lastName.updateValueAndValidity();
    this.prePhone.markAsTouched();
    this.prePhone.updateValueAndValidity();
    this.phone.markAsTouched();
    this.phone.updateValueAndValidity();
    this.email.markAsTouched();
    this.email.updateValueAndValidity();
    this.address.markAsTouched();
    this.address.updateValueAndValidity();
    this.city.markAsTouched();
    this.city.updateValueAndValidity();
    this.country.markAsTouched();
    this.country.updateValueAndValidity();
    this.state.markAsTouched();
    this.state.updateValueAndValidity();
    this.zipCode.markAsTouched();
    this.zipCode.updateValueAndValidity();
    this.poolDimension.markAsTouched();
    this.poolDimension.updateValueAndValidity();
    
    //scrolling to errors 
    if(this.firstName.invalid || this.lastName.invalid || this.email.invalid) {     //error in row 1
      const row1 = this.elementRef.nativeElement.querySelectorAll('#row1')[0];
      row1.scrollIntoView({behavior: 'smooth'}); //scroll to the question   
    }
    else if(this.phone.invalid || this.country.invalid || this.city.invalid) {     //error in row 2
      const row2 = this.elementRef.nativeElement.querySelectorAll('#row2')[0];
      row2.scrollIntoView({behavior: 'smooth'}); //scroll to the question   
    }
     else if(this.poolDimension.invalid || this.address.invalid || this.zipCode.invalid) {      //error in row 3
      const row3 = this.elementRef.nativeElement.querySelectorAll('#row3')[0];
      row3.scrollIntoView({behavior: 'smooth'}); //scroll to the question   
    }
    
  //google analytic
    if(this.regForm.get('personalDetails').valid){
      console.log("step 2 valid");
      const gtmTag = {
        event: 'continue buttons',
        eventCategory: 'continue buttons',
        eventAction: 'step 2'
      };
      this.gtmService.pushTag(gtmTag);
    }
    else{
      console.log("step 2 invalid");
      return false;
    }
  }


//function to check the email
onCheckEmail(){
  if(this.email.value == "" || this.email.value == null){ // marked the email field if he is empty or null
    this.email.markAsTouched();
    this.email.updateValueAndValidity(); 
  }
}

// //function that just marked the code field if he is empty or null
// onCheckCode(){
//   if(this.codeForSecondReg.value == "" || this.codeForSecondReg.value == null){
//     this.codeForSecondReg.markAsTouched();
//     this.codeForSecondReg.updateValueAndValidity(); 
//   } 
// }


      //function that send the temp code to the user email address
      // sendCodeToUserEmail(){
      //   let code = sessionStorage.getItem('codeForSecondReg');
      //   let emailAddress = this.email.value;
      //   let name= this.firstName.value + " " + this.lastName.value;

      //   this.registrationApiService.sendTempCodeForSecondRegProcess(emailAddress , code, name).subscribe(value => {
      //     console.log("res from service about sending temp code to email (for second reg process): " + value);
      //      if(value.toString()=="Y" ){
      //       //open the next div - enter code
      //       this.showEnterCodeForSecondRegDiv= true;
      //       //hide error msg
      //       this.Error500InSendingCodeToEmail = false;
      //       this.email.disable(); //close the email filed to edit
      //       this.SerialNum.disable(); //close the sernum filed to edit- to make sure the user not changing the serial after the confirmation email
      //     }
      //     else{
      //       //show error msg
      //         this.Error500InSendingCodeToEmail = true;
      //     }
      //   },
      //   error => {
      //     console.log("!!!!!!!!!!!!!!!!Server Error 500!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      //   }); 
      // }

    //function to get pre phone of a country by send the country to api
      getPrePhone(country: string){
        this.visitorsService.getPrePhoneByCountry(country).subscribe(res => {
          //console.dir(res[0]);
          let prePhone= res[0]["callingCodes"][0]
          //console.dir(prePhone);
          this.prePhone.setValue(prePhone); //set the pre phone into the input in the form
        },
        error => {
          console.log("restcountries API error");
          this.prePhone.setValue("");
        }); 
      }

    //function to get city name by send the zipCode to api (this is a french api that works only for cites is france)
      keyPressZipCode(zip: string){
        //console.log(zip);
        //activated only if the selected country is france
        zip= zip.trim();
        if(this.country.value == "France"){
          if(zip.length == 5){ //zip codes in france are 5 digit
            this.visitorsService.getCityNameByZipCode(zip).subscribe(res => {
              if(res){
                //console.dir(res);
                try{
                  let cityName= res["features"][0].properties.city;
                  //console.dir(cityName);
                  //bug in api- all the unknown zip values return city= Annecy, the zip code of Annecy is just 74000
                  if(cityName == "Annecy" && zip != "74000" ){
                    this.city.setValue("");
                  }
                  else{
                    this.city.setValue(cityName); //set the city name into the input in the form
                  }
                }
                catch{
                  this.city.setValue("");
                }
              }
              else{
                this.city.setValue("");
              }
            },
            error => {
              console.log("french API error");
              this.city.setValue("");
            }); 
        }        
        else{
          this.city.setValue("");
        }
      }
    }
    
  //get functions
  get firstName() {
    return this.regForm.get('personalDetails').get('firstName');
  }
  get lastName() {
    return this.regForm.get('personalDetails').get('lastName');
  }
  get email() {
    return this.regForm.get('personalDetails').get('email');
  }
  get prePhone() {
    return this.regForm.get('personalDetails').get('prePhone');
  }
  get phone() {
    return this.regForm.get('personalDetails').get('phone');
  }
  get country() {
    return this.regForm.get('personalDetails').get('country');
  }
  get state() {
    return this.regForm.get('personalDetails').get('state');
  }
  get city() {
    return this.regForm.get('personalDetails').get('city');
  }
  get address() {
    return this.regForm.get('personalDetails').get('address');
  }
  get zipCode() {
    return this.regForm.get('personalDetails').get('zipCode');
  }
  get poolDimension() {
    return this.regForm.get('personalDetails').get('poolDimension');
  }
  get poolLength() {
    return this.regForm.get('personalDetails').get('poolLength');
  }
  get poolWidth() {
    return this.regForm.get('personalDetails').get('poolWidth');
  }

}
