import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[PhoneDirective]'
})
export class PhoneDirective {

  constructor(public ngControl: NgControl) { }

  // @HostListener('ngModelChange', ['$event'])
  // onModelChange(event) {
  //   //console.dir(event);
  //   //console.log("phone directive- ngModelChange");
  //   this.onInputChange(event);
  // }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    //console.log(event);
    this.onInputChange(event);
  }

  // @HostListener('keydown.Backspace', ['$event'])
  // keydownBackspace(event) {
  //   console.log("phone directive- keydown.Backspace");
  //   this.onInputChange(event.target.value);
  // }

  onInputChange(event) {
    //console.log("phone directive " + event.which);
    //allow all numbers +- and Tab,backspace, left & right arrows
    // if ((event.which < 48 || event.which > 57) && ((event.which > 105 || event.which < 96)) && event.key != "+" && event.key != "-" && event.key != "Tab" && event.key != "Backspace" && event.which !=37 && event.which !=39) {
    //   //console.log("not allowed");
    //   event.preventDefault();
    // }

    //(french use dot after each 2 digit in phones numbers)
    if ((event.which < 48 || event.which > 57) && ((event.which > 105 || event.which < 96)) && event.key != "."  && event.key != "Tab" && event.key != "Backspace" && event.which !=37 && event.which !=39) {
      //console.log("not allowed");
      //  console.log(event.key);
      //  console.log(event.which);
      event.preventDefault();
    }
  }
 
}
