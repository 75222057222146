<footer>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 col-md-3 formTit">
                    <p><a target="_blank" href="https://www.maytronics.fr/">https://www.maytronics.fr/</a></p> 
                    <p><a target="_blank" href="https://www.robot-dolphin.fr/">https://www.robot-dolphin.fr/</a></p>
                </div>
                <div class="col-sm-4 col-md-4 formTit">
                       <!-- Follow Us {{message}} -->
                      <!-- <p> {{'FollowUs' | translate }}</p>
                      <p>
                        <a href="https://www.facebook.com/maytronics" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/facebook.png" alt=""> </a>
                        <a href="https://www.instagram.com/maytronics/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/instagram.png" alt=""> </a>
                        <a href="https://www.youtube.com/user/maytronicsgroup/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/youtube.png" alt=""> </a>
                        <a href="https://www.linkedin.com/company/maytronics/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/linkedin.png" alt=""> </a>
                    </p>  -->
                </div>
                <div class="col-sm-5 col-md-5" style="text-align: right;">
                    <!-- <p>
                         {{'footerMsg' | translate }} 
                    </p> -->
                    <p style="font-weight: bold;font-size: 15px;"> {{'FollowUs' | translate }}</p>
                      <p>
                        <a href="https://www.facebook.com/maytronics" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/FacebookNew.png" alt=""> </a>
                        <!-- <a href=""> <img class="socialMediaIcons" src="assets/icons/socialMedia/twitter_circle.png" alt=""> </a> -->
                        <a href="https://www.instagram.com/maytronics/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/InstagramNew.png" alt=""> </a>
                        <a href="https://www.youtube.com/user/maytronicsgroup/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/YoutubeNew.png" alt=""> </a>
                        <a href="https://www.linkedin.com/company/maytronics/" target="_blank"> <img class="socialMediaIcons" src="assets/icons/socialMedia/LinkedInNew.png" alt=""> </a>
                    </p> 
                </div>
            </div>
            <hr style="border-top: 1px solid #E9E7E7;margin: 5px;">
            <div class="row">
                <div class="col-md-6 col-sm-6 pad15">
                    <p>©{{year}} Maytronics | 
                        <a target="_blank" href="{{'privacyPolicyUrl' | translate }}">{{'privacyPolicy' | translate }} |</a>
                        <a target="_blank" href="{{'termsOfServiceUrl' | translate }}"> {{'termsOfService' | translate }}</a>
                        <!-- <a target="_blank" href="{{'cookiePolicyUrl' | translate }}"> {{'cookiePolicy' | translate }}.</a> -->
                     </p>
                </div>
               <div class="col-md-6  col-sm-6 pad15">
                <a href=""><img class="logoWhite" src="../../../assets/logos/White.png" alt="Maytronics - Robotic Pool Cleaner">
                </a>
             </div>
               
            </div>
        </div>
    </div>
</footer>

