import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgxMatDatetimePickerModule,  NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';

//import { NgxMatMomentModule  } from 'ngx-mat-moment-adapter';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
      MatDatepickerModule,
      MatInputModule,
      MatButtonModule,
      MatNativeDateModule,
      //NgxMatMomentModule
      // NgxMatDatetimePickerModule,
      // NgxMatTimepickerModule,
  ],
 
  exports:[
    MatDatepickerModule,
      MatInputModule,     
      MatButtonModule,
      MatDatepickerModule,
      MatNativeDateModule
      //NgxMatMomentModule
      // NgxMatDatetimePickerModule,
      // NgxMatTimepickerModule 
  ],
  
})

export class DatePickerModule { }
