
    <!--add ngStyle that after complete the reg we prevent from users go back to the other steps -->
<div class="content" style="margin-top: 0px;margin-bottom: 0px;" role="main" [ngStyle]="{pointerEvents:RegParams.RegCompleted?'none':''}">
    <form id="regForm" [formGroup]="registrationForm">
        <mat-horizontal-stepper #stepper linear labelPosition="bottom" style="min-height: 800px;">

            <!--step1-->
            <mat-step  label="Product Info" [state]="Step1State" formGroupName="alarmDetails" [stepControl]="registrationForm.get('alarmDetails')">
                    <ng-template matStepLabel><span class="stepsLabels">{{'ProductInfo' | translate }} </span> </ng-template>
                    <app-step1  [regForm]="registrationForm" [RegParams]="RegParams"></app-step1>
            </mat-step>

            <!--step2-->
            <mat-step label="Personal Info" [state]="Step2State"  formGroupName="personalDetails" [stepControl]="registrationForm.get('personalDetails')">
                <ng-template matStepLabel> <span class="stepsLabels">{{'MyInfo' | translate }} </span></ng-template>
                <app-step2 [regForm]="registrationForm" [RegParams]="RegParams"></app-step2>
            </mat-step>

            <!--step3-->
            <mat-step label="Reseller Info" [state]="Step3State" formGroupName="resellerDetails" [stepControl]="registrationForm.get('resellerDetails')" >
                <ng-template matStepLabel> <span class="stepsLabels">{{'resellerInfo' | translate }} </span></ng-template>
                <app-step3  [regForm]="registrationForm" [RegParams]="RegParams"></app-step3>
            </mat-step>

            <!--step4-->
            <mat-step label="Summary" [completed]="RegParams.RegCompleted" [state]="Step4State">
                <ng-template matStepLabel> <span class="stepsLabels">{{'Summary' | translate }} </span></ng-template>
                <!-- <ng-template matStepLabel>Summary</ng-template> -->
                <app-step4 [regForm]="registrationForm" [RegParams]="RegParams" (regResult)="getRegResultFromStep4($event)"></app-step4>
              </mat-step>





      <!-- Icon overrides for steps. for each step we have uniq icon (replacing the regular number that was shown) -->
      <ng-template matStepperIcon="alarmInfo">
        <span class="material-icons">receipt_long</span>
      </ng-template>
      
      <ng-template matStepperIcon="personalInfo">
        <span class="material-icons"> person</span>
      </ng-template>

      <ng-template matStepperIcon="resellerInfo">
        <!-- <img style="max-width: 50px;" src="assets/icons/steps/robotBlue.png" alt=""> -->
        <span class="material-icons"> sell</span>
      </ng-template>

      <ng-template  matStepperIcon="summary">
        <span class="material-icons">thumb_up</span>
      </ng-template>

      <!--for all steps- when complete we will override the step icon and put the V icon -->
      <ng-template matStepperIcon="done">
        <span class="material-icons">check</span>
      </ng-template>

       <!--for step 4 if the reg was failed in some reason-->
       <!-- <ng-template matStepperIcon="failed">
        <span class="material-icons">close</span>
      </ng-template> -->

      <!--Override the pencil icon that shown in edit mode, we will present the main icon for this step without change it on edit mode -->
      <ng-template matStepperIcon="edit" let-index="index">
        <!-- <img *ngIf="(index +1) == 1" style="max-width: 50px;" src="assets/icons/steps/robotBlue.png" alt=""> -->
        <span *ngIf="(index +1) == 1" class="material-icons"> receipt_long</span>
        <span *ngIf="(index +1) == 2" class="material-icons"> person</span>
        <span *ngIf="(index +1) == 3" class="material-icons"> sell</span>
        <span *ngIf="(index +1) == 4" class="material-icons"> thumb_up</span>
      </ng-template>

  </mat-horizontal-stepper>
</form>

</div>


