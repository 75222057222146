<!--if some how the user manage to enter step 2 without valid serNum we will tell him to go back to step 1 and fix it. if there is a value in the part it means that the serNum was recognize and valid-->
<div class="center" *ngIf="!RegParams.Part" style="min-height: 500px;">
    <h3 class="errorMsg" style="margin-top:100px">{{'ProblemInSerNum' | translate }}</h3>
</div>

<div *ngIf="RegParams.Part" id="personal-details" [formGroup]="regForm" style="margin-top: 15px;">
        <h2>{{'PersonalInfo' | translate }}</h2>
        <div class="row" id="row1">
            <!--FirstName-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'FirstName' | translate }}</label>
                <input type="text" class="form-control" formControlName="firstName" maxlength="20">
                <!--error msg-->
                <p *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="errorMsg">
                    <span *ngIf="firstName.errors.required">{{'Required' | translate }}</span>
                </p>
                <!--End error msg-->
            </div>
    
            <!--LastName-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'LastName' | translate }}</label>
                <input type="text" class="form-control" formControlName="lastName" maxlength="30">
                <!--error msg-->
                <p *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="errorMsg">
                    <span *ngIf="lastName.errors.required">{{'Required' | translate }}</span>
                </p>
                <!--End error msg-->
            </div>
    
            <!--Email-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'Email' | translate }}</label>
                <input type="email" #emailInp1 class="form-control" formControlName="email" maxlength="40" (ngModelChange)="checkEmail(emailInp1.value)">
                <!--error msg-->
                <p *ngIf="email.invalid && (email.dirty || email.touched)" class="errorMsg">
                    <span *ngIf="email.errors.required">{{'Required' | translate }}</span>
                    <span *ngIf="email.errors && !(email.errors.required)">{{'InvalidEmail' | translate }}</span>
                </p>
                <!--End error msg-->
                </div>
            </div>
    
        <div class="row" id="row2">
            <!--Country-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'Country' | translate }}</label>
                <input type="text" class="form-control" [formControl]="countryControl" [matAutocomplete]="auto"
                    (input)="resetCountry()">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCountry">
                    <mat-option *ngFor="let c of filteredCountries | async;index as i" [value]="c"
                        (onSelectionChange)="changeCountry(c, $event)" >
                        {{c}}
                    </mat-option>
                </mat-autocomplete>
                  <!--error msg-->
                  <p *ngIf="countryControl.invalid && (countryControl.dirty || countryControl.touched)" class="errorMsg">
                    <span *ngIf="countryControl.errors.required">{{'Required' | translate }}</span>
                </p>
                <p *ngIf="countryControl.valid && country.invalid" class="errorMsg">
                    <span *ngIf="country.errors.required">{{'MustSelectFromTheList' | translate }}</span>
                </p>
                <!--End error msg-->
            </div>

            <!--State-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'State' | translate }}</label>
                    <!-- <input type="text" class="form-control" formControlName="state"> -->
                    <input type="text" class="form-control" [formControl]="stateControl" [matAutocomplete]="auto1"
                    (input)="resetState()">
                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnState">
                    <mat-option *ngFor="let s of filteredStates | async;index as i" [value]="s"
                         (onSelectionChange)="changeState(s, $event)">
                        {{s}}
                    </mat-option>
                </mat-autocomplete>
                <!--error msg-->
                <p *ngIf="stateControl.invalid && (stateControl.dirty || stateControl.touched)" class="errorMsg">
                    <span *ngIf="stateControl.errors.required">{{'Required' | translate }}</span>
                </p>
                <p *ngIf="stateControl.valid && state.invalid" class="errorMsg">
                    <span *ngIf="state.errors.required">{{'MustSelectFromTheList' | translate }}</span>
                </p>
                <!--End error msg-->
            </div>


            <!--Phone-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'Phone' | translate }}</label>
                <div style="display: flex;">
                    <span style="margin: 10px 3px 0px 0px">+</span>
                    <input type="text" class="form-control" style="width: 33%;" formControlName="prePhone" maxlength="3" PhoneDirective [pattern]="PrePhoneNumberPattern">
                    <span style="margin: 10px 3px 0px 3px;"> - </span>
                    <input type="text" class="form-control" formControlName="phone" maxlength="15" PhoneDirective [pattern]="PhoneNumberPattern">
    
                </div>
                <!--error msg-->
                <div style="display: flex;">
                    <p *ngIf="(prePhone.invalid && (prePhone.dirty || prePhone.touched))" class="errorMsg" >
                        <span *ngIf="prePhone.errors.required">{{'Required' | translate }}</span>
                        <span *ngIf="prePhone.errors.pattern"> {{'PhoneInvalid' | translate }} </span>
                    </p>
                    <p *ngIf="(phone.invalid && (phone.dirty || phone.touched))"  style="margin-left: 100px;" class="errorMsg">
                        <span *ngIf="phone.errors.required">{{'Required' | translate }}</span>
                        <span *ngIf="phone.errors.pattern"> {{'PhoneInvalid' | translate }} </span>
                    </p>
                   
                </div>
                <!--End error msg-->
            </div>
        </div>
    
        <div class="row" id="row3">
            <!--ZipCode-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'ZipCode' | translate }}</label>
                <input type="text" #zipCode class="form-control" formControlName="zipCode" maxlength="10" (blur)="keyPressZipCode(zipCode.value)">
                <!--error msg-->
            <p *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="errorMsg">
                <span *ngIf="zipCode.errors.required">{{'Required' | translate }}</span>
            </p>
            <!--End error msg-->
                </div>

                 <!--City-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>* {{'City' | translate }}</label>
               <!-- <mat-select class="form-control" #stateSelect  formControlName="city">
                   <mat-option class="" *ngFor="let s of statesList" [value]="s">
                        {{s}}
                   </mat-option>
               </mat-select> -->
               <input type="text" class="form-control" formControlName="city" maxlength="30">
                <!--error msg-->
               <p *ngIf="city.invalid && (city.dirty || city.touched)" class="errorMsg">
                   <span *ngIf="city.errors.required">{{'Required' | translate }}</span>
               </p>
               <!--End error msg-->
           </div>

            <!--Address-->
            <div class="form-group col-md-4" formGroupName="personalDetails">
                <label>{{'Address' | translate }}</label>
                <input type="text" class="form-control" formControlName="address" maxlength="32">
                <!--error msg-->
                <p *ngIf="address.invalid && (address.dirty || address.touched)" class="errorMsg">
                    <span *ngIf="address.errors.required">{{'Required' | translate }}</span>
                </p>
                <!--End error msg-->
            </div>

        </div>

        <div class="row" id="row4">
                <!--Pool dimension-->
                <div class="form-group col-md-4" formGroupName="personalDetails">
                    <!-- <i class="material-icons infoIconToPopup" data-target="#SeePoolDimensionExpModal" data-toggle="modal">info</i> -->
                    <img class="" src="../../../../../assets/icons/Info circled.png" alt="" data-target="#SeePoolDimensionExpModal" data-toggle="modal"  style="cursor:pointer;max-height: 25px;">
                    <label class="textNextToIcon" style="margin-left: 5px;">{{'poolDimension' | translate }} 
                    </label> 
                    <mat-select class="form-control"  formControlName="poolDimension" #poolDim (selectionChange)="changePoolDim(poolDim.value)">
                        <mat-option class="" value= "1" > {{'FreeForm' | translate }}</mat-option>
                         <mat-option class="" value= "0" > {{'NotFreeForm' | translate }}</mat-option>
                    </mat-select>
                </div>

                <!--length and width (display only if the user selected not freeform)-->
                <div *ngIf="poolDim.value=='0'" class="form-group col-md-4" formGroupName="personalDetails">
                    <label class="textNextToIcon">{{'Length' | translate }} </label> 
                    <input type="text" class="form-control" formControlName="poolLength" maxlength="3" PhoneDirective [pattern]="PoolSizePattern">
                </div>
                <div *ngIf="poolDim.value=='0'" class="form-group col-md-4" formGroupName="personalDetails">
                    <label class="textNextToIcon">{{'Width' | translate }} </label> 
                    <input type="text" class="form-control" formControlName="poolWidth" maxlength="3" PhoneDirective [pattern]="PoolSizePattern">
                </div>

        </div>

       
    </div>

  <br/>

  <!--Buttons-->
  <div class="center margPC">
    <button  id="step2BackBtn" [ngClass]="'Variable_btnBackClass' | translate"  mat-button matStepperPrevious><i class="material-icons">{{'Variable_arrow_ios' | translate }}</i> {{'Back' | translate }}</button>
    &nbsp;
    
    <!--button to check the code if it is second reg-->
    <!-- <button id="checkCodeBtn" *ngIf="this.RegParams.isRegExist=='Y' && this.showEnterCodeForSecondRegDiv && !this.showPersonalInfoDiv"  (click)="onCheckCode()"  class="btnNext"  mat-button>{{'CheckCode' | translate }}</button> -->
    
     <!--submit step button-->
    <button id="step2NextBtn"  class="btnNext"  mat-button matStepperNext (click)="step2Submitted()">{{'Continue' | translate }}</button>
  </div>

    <!--enter code for second reg users-->
    <!-- <div class="row" id="enterCodeForSecondRegDiv"  *ngIf="">
        <div class="col-md-6">
            <div class="form-group col-md-12" formGroupName="personalDetails" >
             <label>{{'CodeSent' | translate }}</label><br>
            <label>* {{'EnterCode' | translate }}</label>
            <div class="input-group">
                <input type="text" #code class="form-control" [ngClass]="'Variable_input-group' | translate" formControlName="codeForSecondReg" maxlength="50" (blur)="checkCode(code.value)" (keypress)="keyPressCode($event ,code.value)">
                <span (click)="checkCode(code.value)" class="input-group-addon" [ngClass]="'Variable_input-group-addon' | translate"><i class="material-icons" [ngClass]="(!spinEmail || this.showPersonalInfoDiv || regForm.get('personalDetails').get('email').errors) || serverError500Email ? '' :'spin'" > send</i></span>
            </div> -->
        <!--error msg-->
        <!-- <p *ngIf="codeForSecondReg.invalid && (codeForSecondReg.dirty || codeForSecondReg.touched)" class="errorMsg">
            <span *ngIf="codeForSecondReg.errors.required">{{'Required' | translate }}</span>
            <span *ngIf="codeForSecondReg.errors  && !(codeForSecondReg.errors.required)">{{'InvalidCode' | translate }}</span>
        </p>
        </div>
      </div>
    </div> -->



    <!--Modal that explain about pool dimension-->
<div class="modal fade warranty-info" id="SeePoolDimensionExpModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div class="wpb_wrapper" style="padding-left: 15px; padding-right: 15px;">
                    <h3 style="font-size: 25px;color: #000000;line-height: 30px;" class="vc_custom_heading">{{'poolDimension' | translate }}</h3>
                    <hr style="margin-top: 0px; border-top: 1px solid  #E9E7E7;">
                    
                    <div class="wpb_single_image wpb_content_element vc_align_center">
                            {{'poolDimensionExp' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
