
  <div [ngStyle]="{'direction':'Variable_direction' | translate}">
    <app-language></app-language>
  </div>

  <!-- Toolbar -->
  <!-- <div style="background-color: white;">
    <app-toolbar></app-toolbar>
  </div> -->

  <!-- introduction -->
  <!-- <div class="introDiv" [ngStyle]="{'direction':'direction' | translate}"> -->
  <div [ngClass]="'Variable_introductionClass' | translate"  [ngStyle]="{'direction':'Variable_direction' | translate}" >
      <app-introduction [ngStyle]="{'text-align':'Variable_text-align' | translate}"></app-introduction>
  </div>

    <!-- mainform (steps) -->
  <div [ngStyle]="{'direction':'Variable_direction' | translate}">
      <app-mainform [ngStyle]="{'text-align':'Variable_text-align' | translate}"></app-mainform>
  </div>

    <!-- Footer -->
    <div [ngStyle]="{'direction':'Variable_direction' | translate}">
      <app-footer [ngStyle]="{'text-align':'Variable_text-align' | translate}"></app-footer>
    </div>


  <router-outlet></router-outlet>
